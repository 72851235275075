<template>
  <div id="view-admin-product-managment">
    <div class="level">
      <div class="level-left">
        <b-select v-model="perPage" :disabled="!isPaginated" class="level-item">
          <option value="5">5 per page</option>
          <option value="10">10 per page</option>
          <option value="15">15 per page</option>
          <option value="20">20 per page</option>
        </b-select>
      </div>
      <div class="level-right">
        <a @click="onAdd()" class="button is-small is-success is-light">New</a>
      </div>
    </div>
    <b-table
      :data="history"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :pagination-position="paginationPosition"
      striped
      default-sort="name"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      icon-pack="fas"
    >
      <b-table-column
        field="posted"
        label="Posted"
        width="30"
        numeric
        v-slot="props"
      >
        {{ formatDate(props.row.posted) }}
      </b-table-column>

      <b-table-column
        field="product.name"
        width="auto"
        label="Name"
        v-slot="props"
      >
        {{ props.row.title }}
      </b-table-column>

      <b-table-column label="Tools" v-slot="props" sticky width="140">
        <span>
          <a
            @click="onAdd(props.row)"
            class="button is-small is-success is-light mr-1"
            >Edit</a
          >
          <a
            @click="onDelete(props.row)"
            class="button is-small is-danger is-light"
            >Delete</a
          >
        </span>
      </b-table-column>
    </b-table>
    <b-modal
      v-model="isAddDialogActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      width="80%"
      scroll="keep"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <add-dialog
          :session="session"
          :edit="editItem"
          @onComplete="onAddComplete"
          @onCancel="onAddCancel"
        ></add-dialog>
      </template>
    </b-modal>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import AddDialog from "./ProductHistoryEdit";

export default {
  name: "view-admin-product-history-list",
  components: {
    AddDialog
  },
  props: {
    product: Object,
    session: Object
  },
  data() {
    return {
      history: [],
      isAddDialogActive: false,
      editItem: null,
      disabled: false,
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "bottom",
      tdefaultSortDirection: "asc",
      tsortIcon: "arrow-up",
      tsortIconSize: "is-small",
      currentPage: 1,
      perPage: 10
    };
  },
  watch: {
    product(to, from) {
      if (to == from) return;
      this.getHistory(to.productId);
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.disabled = true;
      if (this.product.productId > 0) {
        API.put(
          API.url.product.product,
          this,
          this.product.productId,
          this.product,
          function(obj) {
            obj.disabled = false;
            obj.showMessage("Changes Saved!.", "is-success");
          },
          function(obj, err) {
            obj.disabled = false;
            obj.showMessage("Server returned an error: " + err);
            obj.$router.back();
          }
        );
      } else {
        API.post(
          API.url.product.product,
          this,
          this.product,
          function(obj, data) {
            obj.disabled = false;
            if (data.login == null) {
              obj.showMessage("Unable to create this product.");
              return;
            }
            obj.showMessage("Changes Saved!.", "is-success");
            obj.$router.back();
          },
          function(obj, err) {
            obj.disabled = false;
            obj.showMessage("Server returned an error: " + err);
          }
        );
      }
    },
    onCancel() {
      this.$router.back();
    },
    onAdd(obj = null) {
      this.editItem = obj;
      this.isAddDialogActive = true;
    },
    onAddComplete(list = null) {
      if (list == null) {
        this.getList();
      }
      this.isAddDialogActive = false;
    },
    onAddCancel() {
      this.isAddDialogActive = false;
    },
    onDelete(obj) {
      var msg = 'Are you sure you want to <b>delete</b> "';
      msg += obj.title + '"? This action cannot be undone.';

      this.$buefy.dialog.confirm({
        title: "Deleting History Item",
        message: msg,
        confirmText: "Delete History Item",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          API.delete(
            API.url.product.product + obj.productId + "/history/",
            this,
            obj.historyId,
            function(obj) {
              obj.getHistory(obj.product.productId);
              obj.$buefy.toast.open("History Item deleted!");
            },
            function(obj, err) {
              obj.showMessage(err);
            }
          );
        }
      });
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    },
    stringsForTags(tagList) {
      var result = [];
      tagList.forEach(function(item) {
        result.push(item.tag != null ? item.tag.name : item.name);
      });
      return result;
    },
    formatDate(str) {
      var d = new Date(str);
      return d.toLocaleDateString("en-US");
    },
    getHistory(productId) {
      API.list(
        API.url.product.product + productId + "/history",
        this,
        function(obj, data) {
          obj.history = data.list;
        },
        function(obj, err) {
          obj.showMessage(err);
        }
      );
    }
  },
  mounted() {}
};
</script>
