<template>
  <div id="view-admin-asset-type-managment">
    <div class="media left">
      <div class="media-left is-hidden-touch">
        <figure class="image is-48x48">
          <img src="/icon/confg_48x48.png" class="icon" alt="Support Icon" />
        </figure>
      </div>
      <div class="media-content">
        <div class="content">
          <div class="title is-5">Asset Type Management</div>
          <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
              <router-link
                to="/admin/asset/type/new"
                class="button is-primary is-light level-item"
                >New</router-link
              >
            </div>
          </div>
          <section>
            <b-loading
              :is-full-page="false"
              v-model="isLoading"
              :can-cancel="false"
            ></b-loading>

            <b-table
              :data="list"
              :paginated="false"
              striped
              default-sort="typeId"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              icon-pack="fas"
            >
              <b-table-column
                field="typeId"
                label="ID"
                width="30"
                numeric
                v-slot="props"
              >
                {{ props.row.typeId }}
              </b-table-column>

              <b-table-column
                field="description"
                width="auto"
                label="Description"
                v-slot="props"
              >
                {{ props.row.description }}
              </b-table-column>

              <b-table-column label="Tools" v-slot="props" sticky width="140">
                <span>
                  <router-link
                    :to="'/admin/asset/type/' + props.row.typeId"
                    class="button is-small is-primary is-light mr-1"
                    >Edit</router-link
                  >
                  <a
                    @click="onDelete(props.row)"
                    class="button is-small is-danger is-light"
                    >Delete</a
                  >
                </span>
              </b-table-column>
            </b-table>
          </section>
        </div>
        <div class="footnotes is-hidden">
          <ol>
            <li id="fn:1"></li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";

export default {
  name: "view-admin-asset-managment",
  components: {},
  data: function() {
    return {
      list: [],
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "bottom",
      tdefaultSortDirection: "asc",
      tsortIcon: "arrow-up",
      tsortIconSize: "is-small",
      currentPage: 1,
      perPage: 5
    };
  },
  props: {
    session: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  methods: {
    onDelete(obj) {
      // use the id to find the asset and then delete it
      var msg = 'Are you sure you want to <b>delete</b> "';
      msg += obj.description + '"? This action cannot be undone.';

      this.$buefy.dialog.confirm({
        title: "Deleting Type",
        message: msg,
        confirmText: "Delete Type",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          API.delete(
            API.url.base.assetType,
            this,
            obj.typeId,
            function(obj) {
              obj.getTypes();
              obj.$buefy.toast.open("Type deleted!");
            },
            function(obj, err) {
              obj.showError(err);
            }
          );
        }
      });
    },
    showError(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: "is-bottom",
        type: "is-danger"
      });
    },
    getTypes() {
      this.isLoading = true;
      API.list(
        API.url.base.assetType,
        this,
        function(obj, data) {
          obj.list = data.list;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showError(err);
          obj.isLoading = false;
        }
      );
    }
  },
  mounted() {
    this.getTypes();
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #view-support {
    asset {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
      .footnote {
        font-size: 8pt;
        vertical-align: top;
      }

      .footnotes {
        font-size: 8pt;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  #view-support {
    asset {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
