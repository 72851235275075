<template>
  <div id="view-admin-product-history-edit">
    <b-card title="Product History Edit" :centered="true">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" alt="Configuration Icon" />
          </figure>
        </div>
        <div class="media-content">
          <form action="" @submit="onSubmit">
            <div class="content">
              <b-field label="Title" label-position="on-border">
                <b-input
                  ref="title"
                  v-model="editItem.title"
                  required
                  autofocus
                  :disabled="disabled"
                ></b-input>
              </b-field>
              <b-field label="Body" label-position="on-border">
                <b-input
                  v-model="editItem.body"
                  required
                  type="textarea"
                  rows="15"
                  cols="80"
                  :disabled="disabled"
                ></b-input>
              </b-field>

              <div class="columns">
                <div class="column"></div>
                <div class="column">
                  <b-field label="Posted" label-position="on-border">
                    <b-datetimepicker
                      v-model="postedDate"
                      editable
                      icon-pack="fas"
                      position="is-top-right"
                      :disabled="disabled"
                    ></b-datetimepicker>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="level is-mobile">
              <div class="level-left">
                <small v-if="error != ''" class="level-item is-danger">
                  {{ error }}
                </small>
              </div>
              <div class="level-right">
                <div class="field is-grouped">
                  <button
                    type="sumbit"
                    class="button is-link mr-1"
                    :disabled="disabled"
                  >
                    Save
                  </button>
                  <a
                    @click="onCancel()"
                    class="button is-light"
                    :disabled="disabled"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";

export default {
  name: "view-admin-product-history-edit",
  components: {
    BCard
  },
  props: {
    edit: Object,
    session: Object
  },
  data() {
    return {
      editItem: [],
      model: [],
      disabled: false,
      postedDate: null,
      error: ""
    };
  },
  watch: {
    edit(to, from) {
      if (to != from) {
        this.editItem = to;
        this.postedDate = new Date(to.posted);
      }
      if (this.editItem == null) {
        this.getModel();
      }
    }
  },
  methods: {
    getModel() {
      API.model(
        API.url.product.product + "history/",
        this,
        function(obj, data) {
          obj.editItem = data;
        },
        function(obj, err) {
          obj.showMessage("Unable to get model: " + err, "is-danger");
        }
      );
    },
    onSubmit(e) {
      e.preventDefault();
      if (this.session.login != null) {
        this.disabled = true;
        if (this.postedDate != null) {
          this.editItem.posted = this.postedDate;
        }
        if (this.editItem.historyId != null && this.editItem.historyId > 0) {
          // edit
          API.put(
            API.url.product.product + this.editItem.productId + "/history/",
            this,
            this.editItem.historyId,
            this.editItem,
            function(obj, data) {
              obj.disabled = false;
              obj.editItem = data;
              obj.showMessage("Changes Saved!.", "is-success");
              obj.$router.back();
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        } else {
          // add
          API.post(
            API.url.product.product + this.editItem.productId + "/history/",
            this,
            this.editItem,
            function(obj, data) {
              obj.disabled = false;
              obj.editItem = data;
              obj.showMessage("Changes Saved!.", "is-success");
              obj.$router.back();
            },
            function(obj, err) {
              obj.disabled = false;
              obj.showMessage("Server returned an error: " + err);
            }
          );
        }
      }
    },
    onCancel() {
      this.$emit("onCancel");
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    }
  },
  mounted() {
    if (this.edit != null) {
      this.editItem = this.edit;
      this.postedDate = new Date(this.editItem.posted);
    } else {
      this.postedDate = new Date();
      this.getModel();
    }
  }
};
</script>
