<template>
  <div id="tools-session-managment">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" class="icon" alt="Support Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">Sessions</div>
            <div class="level">
              <div class="level-left">
                <b-select
                  v-model="perPage"
                  :disabled="!isPaginated"
                  class="level-item"
                >
                  <option value="5">5 per page</option>
                  <option value="10">10 per page</option>
                  <option value="15">15 per page</option>
                  <option value="20">20 per page</option>
                </b-select>
              </div>
              <div class="level-right">
                <a @click="onPurge()" class="button is-small is-danger is-light"
                  >Purge Old Sessions</a
                >
              </div>
            </div>
            <section>
              <b-loading
                :is-full-page="false"
                v-model="isLoading"
                :can-cancel="false"
              ></b-loading>

              <b-table
                :data="list"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                striped
                default-sort="name"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                icon-pack="fas"
              >
                <b-table-column
                  field="Id"
                  label="ID"
                  width="40"
                  numeric
                  v-slot="props"
                >
                  {{ props.row.sessionId }}
                </b-table-column>
                <b-table-column
                  field="Id"
                  label="Expired"
                  width="40"
                  numeric
                  v-slot="props"
                >
                  {{ isExpired(props.row) }}
                </b-table-column>

                <b-table-column width="auto" label="Name" v-slot="props">
                  {{ props.row.login != null ? props.row.login.userName : "" }}
                </b-table-column>

                <b-table-column label="Created" width="240" v-slot="props">
                  {{ formatDate(props.row.created) }}
                </b-table-column>

                <b-table-column label="Expires" width="240" v-slot="props">
                  {{ formatDate(props.row.expires) }}
                </b-table-column>

                <b-table-column label="Tools" v-slot="props" sticky width="140">
                  <span>
                    <a
                      @click="onDelete(props.row)"
                      class="button is-small is-danger is-light"
                      >Delete</a
                    >
                  </span>
                </b-table-column>
              </b-table>
            </section>
          </div>
          <div class="footnotes is-hidden">
            <ol>
              <li id="fn:1"></li>
            </ol>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";

export default {
  name: "tools-session-managment",
  components: {},
  data: function() {
    return {
      session: {},
      list: [],
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "bottom",
      tdefaultSortDirection: "asc",
      tsortIcon: "arrow-up",
      tsortIconSize: "is-small",
      currentPage: 1,
      perPage: 5
    };
  },
  methods: {
    showError(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: "is-bottom",
        type: "is-danger"
      });
    },
    formatDate(str) {
      if (str == null) return "";
      var d = new Date(str);
      return (
        d.toLocaleDateString("en-US") + "  " + d.toLocaleTimeString("en-US")
      );
    },
    isExpired(obj) {
      var d = new Date(obj.expires);
      var n = new Date();
      return d < n;
    },
    onDelete(obj) {
      // use the id to find the article and then delete it
      var msg = 'Are you sure you want to <b>delete</b> "';
      msg += obj.sessionId + "? This action cannot be undone.'";

      this.$buefy.dialog.confirm({
        title: "Deleting Session",
        message: msg,
        confirmText: "Delete Session",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          API.delete(
            API.url.authentication.session,
            this,
            obj.sessionId,
            function(obj) {
              obj.getSessions();
              obj.$buefy.toast.open("Session deleted!");
            },
            function(obj, err) {
              obj.showError(err);
            }
          );
        }
      });
    },
    onPurge() {
      // use the id to find the article and then delete it
      var msg = "Are you sure you want to <b>purge</b> ";
      msg +=
        "all expired sessions over 1 day old? This action cannot be undone.";

      this.$buefy.dialog.confirm({
        title: "Purging Sessions",
        message: msg,
        confirmText: "Purge Sessions",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          API.delete(
            API.url.authentication.session,
            this,
            "",
            function(obj) {
              obj.getSessions();
              obj.$buefy.toast.open("Sessions purged!");
            },
            function(obj, err) {
              obj.showError(err);
            }
          );
        }
      });
    },
    getSessions() {
      this.isLoading = true;
      API.list(
        API.url.authentication.session + "list",
        this,
        function(obj, data) {
          obj.list = data.list;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showError(err);
          obj.isLoading = false;
        }
      );
    }
  },
  mounted() {
    this.getSessions();
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #view-support {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
      .footnote {
        font-size: 8pt;
        vertical-align: top;
      }

      .footnotes {
        font-size: 8pt;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  #view-support {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
