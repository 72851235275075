<template>
  <div id="view-admin-asset-managment">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" class="icon" alt="Support Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">Asset Management</div>
            <div class="level">
              <div class="level-left">
                <b-select
                  v-model="perPage"
                  :disabled="!isPaginated"
                  class="level-item"
                >
                  <option value="5">5 per page</option>
                  <option value="10">10 per page</option>
                  <option value="15">15 per page</option>
                  <option value="20">20 per page</option>
                </b-select>
              </div>
              <div class="level-right">
                <router-link
                  to="/admin/asset/type"
                  class="button is-info is-light mr-1"
                  >Manage Types</router-link
                >

                <router-link
                  to="/admin/asset/new"
                  class="button is-primary is-light level-item"
                  >New</router-link
                >
              </div>
            </div>
            <section>
              <b-loading
                :is-full-page="false"
                v-model="isLoading"
                :can-cancel="false"
              ></b-loading>

              <b-table
                :data="list"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                striped
                default-sort="name"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                icon-pack="fas"
              >
                <b-table-column
                  field="articleId"
                  label="ID"
                  width="30"
                  numeric
                  v-slot="props"
                >
                  {{ props.row.assetId }}
                </b-table-column>

                <b-table-column
                  field="article.description"
                  width="auto"
                  label="Description"
                  v-slot="props"
                >
                  {{ props.row.description }}
                </b-table-column>

                <b-table-column
                  field="short"
                  label="File"
                  width="160"
                  v-slot="props"
                >
                  {{ props.row.fileName }}
                </b-table-column>

                <b-table-column
                  field="short"
                  label="Type"
                  width="160"
                  v-slot="props"
                >
                  {{ props.row.mediaType }}
                </b-table-column>

                <b-table-column
                  field="type.description"
                  label="Type"
                  width="120"
                  v-slot="props"
                >
                  {{ props.row.type.description }}
                </b-table-column>

                <b-table-column label="Tools" v-slot="props" sticky width="140">
                  <span>
                    <router-link
                      :to="'/admin/asset/' + props.row.fileName"
                      class="button is-small is-primary is-light mr-1"
                      >Edit</router-link
                    >
                    <a
                      @click="onDelete(props.row)"
                      class="button is-small is-danger is-light"
                      >Delete</a
                    >
                  </span>
                </b-table-column>
              </b-table>
            </section>
          </div>
          <div class="footnotes is-hidden">
            <ol>
              <li id="fn:1"></li>
            </ol>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";

export default {
  name: "view-admin-asset-managment",
  components: {},
  data: function() {
    return {
      session: {},
      list: [],
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "bottom",
      tdefaultSortDirection: "asc",
      tsortIcon: "arrow-up",
      tsortIconSize: "is-small",
      currentPage: 1,
      perPage: 5
    };
  },
  methods: {
    onDelete(obj) {
      // use the id to find the article and then delete it
      var msg = 'Are you sure you want to <b>delete</b> "';
      msg += obj.title + "? This action cannot be undone.'";

      this.$buefy.dialog.confirm({
        title: "Deleting Asset",
        message: msg,
        confirmText: "Delete Asset",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          API.delete(
            API.url.base.asset,
            this,
            obj.assetId,
            function(obj, data, session) {
              obj.getAssets();
              obj.session = session;
              obj.$buefy.toast.open("Asset deleted!");
            },
            function(obj, err) {
              obj.showError(err);
            }
          );
        }
      });
    },
    showError(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: "is-bottom",
        type: "is-danger"
      });
    },
    getAssets() {
      this.isLoading = true;
      API.list(
        API.url.base.asset,
        this,
        function(obj, data, session) {
          obj.list = data;
          obj.session = session;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showError(err);
          obj.isLoading = false;
        }
      );
    }
  },
  mounted() {
    this.getAssets();
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #view-support {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
      .footnote {
        font-size: 8pt;
        vertical-align: top;
      }

      .footnotes {
        font-size: 8pt;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  #view-support {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
