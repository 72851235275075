<template>
  <div id="component-product-detail">
    <!--article-->
    <div class="media left">
      <div class="media-left">
        <figure class="image is-64x64">
          <img
            :src="
              product == null || product.iconUrl == null
                ? 'https://bulma.io/images/placeholders/128x128.png'
                : product.iconUrl
            "
            alt="Image"
          />
        </figure>
        <div
          class="has-text-centered"
          v-if="product != null && product.downloadLink != null"
        >
          <a :href="product.downloadLink" aria-label="goto download link">
            <span class="icon is-large has-text-info-success">
              <i class="fas fa-2x fa-download" aria-hidden="true"></i>
            </span>
          </a>
        </div>
      </div>
      <div class="media-content">
        <div class="content">
          <div class="title is-5">
            {{ product != null ? product.name : "" }}
          </div>
          <span
            v-if="product != null"
            v-html="compileMarkdown(product.description)"
          ></span>
        </div>
        <section
          class="hero is-mobile"
          v-if="product != null && product.assets != null"
        >
          <b-carousel
            animated="slide"
            :interval="10000"
            icon-pack="fas"
            :autoplay="false"
            :indicator-inside="false"
            :overlay="gallery"
            @click="switchGallery(true)"
          >
            <b-carousel-item
              v-for="asset in product.assets"
              :key="asset.assetId"
            >
              <a class="image ss">
                <img :src="'/api/asset/content/' + asset.assetId" />
              </a>
            </b-carousel-item>
            <span
              v-if="gallery"
              @click="switchGallery(false)"
              class="modal-close is-large"
            />
          </b-carousel>
        </section>

        <div class="level is-mobile">
          <div class="level-left">
            <small class="level-item" v-if="product != null">{{
              formatDate(product.updated)
            }}</small>
          </div>
          <div class="level-right">
            <b-taglist v-if="product != null">
              <b-tag
                type="is-light"
                class="is-1"
                v-for="item in product.tags"
                :key="product.tags.indexOf(item)"
                >{{ item }}</b-tag
              >
            </b-taglist>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <h4 class="is-size-5 mb-2">News</h4>
            <div v-for="item in news" :key="news.indexOf(item)">
              <h6 class="is-size-6 has-text-weight-semibold mb-1">
                {{ formatDate(item.posted) }} -
                {{ item.title }}
              </h6>
              <div
                class="content is-size-6"
                v-html="compileMarkdown(item.body)"
              ></div>
              <hr class="mb-2 mt-2" />
            </div>
          </div>
          <div class="column">
            <h4 class="is-size-5 mb-2">History</h4>
            <div v-for="item in history" :key="history.indexOf(item)">
              <h6 class="is-size-6 has-text-weight-semibold mb-1">
                {{ formatDate(item.posted) }} -
                {{ item.title }}
              </h6>
              <div
                class="content is-size-6"
                v-html="compileMarkdown(item.body)"
              >
                <ul>
                  <li>test</li>
                </ul>
              </div>
              <hr class="mb-2 mt-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/article-->
  </div>
</template>

<script>
import Marked from "marked";
import API from "@/Druware.Web.Shared/api/api";

const url = "/api/product/";

export default {
  name: "component.product.detail",
  props: {
    product: Object,
    session: Object,
  },
  data: function() {
    return {
      history: [],
      news: [],
      gallery: false,
    };
  },
  methods: {
    getImgUrl(value) {
      return "/api/asset/content/" + value;
    },
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        return document.documentElement.classList.add("is-clipped");
      } else {
        return document.documentElement.classList.remove("is-clipped");
      }
    },
    formatDate(str) {
      var d = new Date(str);
      return d.toLocaleDateString("en-US");
    },
    formatDateTime(str) {
      var d = new Date(str);
      return (
        d.toLocaleDateString("en-US") + "  " + d.toLocaleTimeString("en-US")
      );
    },
    compileMarkdown(str) {
      if (str != null) {
        return Marked(str);
      }
      return "";
    },
    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type,
      });
    },
    getHistory(productId) {
      API.list(
        url + productId + "/history",
        this,
        function(obj, data) {
          obj.history = data.list;
        },
        function(obj, err) {
          obj.showMessage(err);
        }
      );
    },
    getNews(productId) {
      API.list(
        url + productId + "/news",
        this,
        function(obj, data) {
          obj.news = data.list;
        },
        function(obj, err) {
          obj.showMessage(err);
        }
      );
    },
  },
  watch: {
    product(to, from) {
      if (to == from) return;
      this.getHistory(to.productId);
      this.getNews(to.productId);
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.history-body {
  ul {
    list-style: list-item;
    background-color: grey;
    li {
      margin: 10px;
      list-style: list-item;
      list-style-type: disc;
      list-style-position: inside;
    }
  }
}

.ss {
  min-height: 128px;
}

@media screen and (min-width: 1px) {
  #component-product-summary {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
    }
  }
}
@media screen and (min-width: 767px) {
  #component-product-detail {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
